import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRespondentDetails,
  getActiveSurveys,
  getPages,
  getCountryCodes,
  getPageData,
  getSurveyLookupFiling,
  getPageFilings,
  getAllPages,
  getQuestionGroups,
  saveAnswers,
  getNextPage,
  getPreviousPage,
  validatePage,
  errorCorrectionQes,
  saveCorrectionQues,
  getPageDataOfLastYear,
  getAllErrors,
  getSectionErrors,
  getHistoricalFiling,
  getSurveyFilingDto,
  getSectionsByUser,
  getSupplementalFiling,
  displaySection,
  getGlossary,
  getPrintSurveyMenu,
  getIsAPILoggedIn,
  getLoginData,
  getBuilderLoginData,
} from "./Survey.service";

const initialState = {
  showFilingList: false,
  isbuilderLogin: false,
  respondentDetails: [],
  activeSurveys: [],
  surveyFiling: [],
  surveyFilingDto: null,
  supplementalFiling: [],
  allPages: [],
  supPages: [],
  pageFilings: [],
  supPageFilings: [],
  sectionFilings: [],
  supSectionFiling: [],
  printSecFilings: [],
  currentSelection: { sectionId: null, pageId: null },
  openedQuestions: {},
  countryCodes: [],
  questionsInPage: [],
  pageLoading: false,
  errorPageLoading: false,
  pageError: null,
  saveLoading: false,
  saveError: null,
  pagePayload: null,
  showLanding: true,
  showErrorPage: false,
  nextPageDetails: null,
  previousPageDetails: null,
  errorPageDetails: {},
  correctionPageDetails: {},
  correctionSaveResponse: {},
  lastYearPageDataResponse: null,
  historicalFiling: false,
  sectionsData: [],
  glossary: null,
  printSurveyMenu: [],
  combinedFilings: [],
  loggedInUserData: null,
  builderUserData: null,
  disableSurvey: false,
  isOpde: false,
  updateStatuses: true,
  builderOrgId: null,
  loginTextCurrentView: [],
  disableAdmin: false,
};

export const fetchRespondentDetailsByUsername = createAsyncThunk(
  "Respondent/Username",
  async (Username) => {
    try {
      const response = await getRespondentDetails(Username);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// export const fetchActiveSurveysByRespondentId = createAsyncThunk(
//   "ActiveSurveys/RespondentId",
//   async (RespondentId, { dispatch, getState }) => {
//     try {
//       const response = await getActiveSurveys(RespondentId);
//       const SurveyId = response.data[0].id;
//       const surveyFilingId = getState().survey.surveyFilingDto?.id;
//       // let payload = {
//       //   surveyFilingId: surveyFilingId,
//       //   surveyRespondentId: RespondentId,
//       // };
//       // await dispatch(fetchSectionsBySurveyIdAndRespondenetId(payload));
//       await dispatch(fetchAllPagesBySurveyId(SurveyId));
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const fetchSectionsBySurveyIdAndRespondenetId = createAsyncThunk(
  "Sections/fetchBySurveyIdRespondentID",
  async (payload) => {
    try {
      const response = await getSectionsByUser(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPrintSections = createAsyncThunk(
  "PrintSections/fetchBySurveyIdRespondentID",
  async (payload) => {
    try {
      const response = await getSectionsByUser(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSupSectionFiling = createAsyncThunk(
  "SupplementalSectionFiling/surveyFilingId",
  async (payload) => {
    try {
      const response = await getSectionsByUser(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPagesBySectionId = createAsyncThunk(
  "Pages/SectionId",
  async (SectionId) => {
    try {
      const response = await getPages(SectionId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAllPagesBySurveyId = createAsyncThunk(
  "AllPages/SurveyId",
  async (SurveyId) => {
    try {
      const response = await getAllPages(SurveyId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSupPages = createAsyncThunk(
  "SupPages/SurveyId",
  async (SurveyId) => {
    try {
      const response = await getAllPages(SurveyId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchQuestionsInPage = createAsyncThunk(
  "survey/page/questions",
  async (payload) => {
    try {
      const response = await getPageData(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSectionData = createAsyncThunk(
  "survey/print",
  async (payload) => {
    try {
      const response = await displaySection(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchNextPage = createAsyncThunk(
  "survey/page/questions/next",
  async (payload) => {
    try {
      const response = await getNextPage(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPreviousPage = createAsyncThunk(
  "survey/page/questions/previous",
  async (payload) => {
    try {
      const response = await getPreviousPage(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const validateSurvey = createAsyncThunk(
  "survey/validate",
  async (payload) => {
    try {
      const response = await validatePage(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCorrectionQes = createAsyncThunk(
  "survey/validate/correction",
  async (payload) => {
    try {
      const response = await errorCorrectionQes(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const correctionQuesSave = createAsyncThunk(
  "survey/validate/correction/save",
  async (payload) => {
    try {
      const response = await saveCorrectionQues(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCountryCodes = createAsyncThunk(
  "questions/countryCodes",
  async () => {
    try {
      const response = await getCountryCodes();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSurveyFiling = createAsyncThunk(
  "survey/fetchSurveyFiling",
  async (payload) => {
    try {
      const response = await getSurveyLookupFiling(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPageFilingsBySurveyFilingId = createAsyncThunk(
  "pageFilings/surveyFiling",
  async (SurveyFilingId) => {
    try {
      const response = await getPageFilings(SurveyFilingId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSupPageFilings = createAsyncThunk(
  "supPageFilings/surveyFiling",
  async (SurveyFilingId) => {
    try {
      const response = await getPageFilings(SurveyFilingId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const saveAnswerOptions = createAsyncThunk(
  "survey/save",
  async (payload) => {
    try {
      const response = await saveAnswers(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPageDataOfLastYear = createAsyncThunk(
  "survay/populatePageHistory",
  async (payload) => {
    try {
      const response = await getPageDataOfLastYear(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSectionErrors = createAsyncThunk(
  "survey/SectionErrors",
  async (payload) => {
    try {
      const response = await getSectionErrors(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAllErrors = createAsyncThunk(
  "survey/AllErrors",
  async (payload) => {
    try {
      const response = await getAllErrors(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSurveyFiling = createAsyncThunk(
  "survey/surveyFiling",
  async (surveyFilingId) => {
    try {
      const response = await getSurveyFilingDto(surveyFilingId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getGlossaryData = createAsyncThunk(
  "survey/Glossary",
  async (payload) => {
    try {
      const response = await getGlossary(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPrintSurveyMenu = createAsyncThunk(
  "survey/printSurveyMenu",
  async (payload) => {
    try {
      const response = await getPrintSurveyMenu(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchHistoricalFiling = createAsyncThunk(
  "survey/historicalFiling",
  async (dto) => {
    try {
      const response = await getHistoricalFiling(dto);
      return response?.data?.percentPagesCompleted ? true : false;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSupplementalFiling = createAsyncThunk(
  "SupplementalFiling/surveyFiling",
  async (payload) => {
    try {
      const response = await getSupplementalFiling(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const checkLoginStatus = createAsyncThunk("survay/login", async () => {
  try {
    const response = await getIsAPILoggedIn();
    console.log("the login status is: ", response);
    return response?.data;
  } catch (error) {
    throw error;
  }
});

export const getLoginAuthenticated = createAsyncThunk(
  "survay/login/auth",
  async () => {
    try {
      const response = await getLoginData();
      console.log("the login data status: ", response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getBuilderUserData = createAsyncThunk(
  "survay/login/builder",
  async (surveyFilingId) => {
    try {
      const response = await getBuilderLoginData(surveyFilingId);
      console.log("the login data status: ", response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    resetSectionsData: (state) => {
      state.sectionsData = [];
    },
    setDataIds: (state, action) => {
      const { secId, pgId } = action.payload;
      state.currentSelection.sectionId = secId;
      state.currentSelection.pageId = pgId;
    },
    setQuestionsData(state, action) {
      const { pageId, data } = action.payload;
      state.openedQuestions[pageId] = data;
    },
    setPagePayload(state, action) {
      state.pagePayload = action.payload;
    },
    setShowLanding(state, action) {
      state.showLanding = action.payload;
    },
    setShowErrorPage(state, action) {
      state.showErrorPage = action.payload;
    },
    setBuilderLogin(state, action) {
      state.isbuilderLogin = action.payload;
    },
    setShowFilingList(state, action) {
      state.showFilingList = action.payload;
    },
    setCombinedSectionFilings(state, action) {
      state.combinedFilings = action.payload;
    },
    setDisableSurvey(state, action) {
      state.disableSurvey = action.payload;
    },
    setIsOpde(state, action) {
      state.isOpde = action.payload;
    },
    setBuilderOrgId(state, action) {
      state.builderOrgId = action.payload;
    },
    setTotalSectionData: (state, action) => {
      const newData = action.payload;

      // Check if data for this id already exists
      const existingDataIndex = state.sectionsData.findIndex(
        (item) => item.id === newData?.section?.id
      );

      if (existingDataIndex !== -1) {
        // Merge existing data with new data
        console.log("the data is: ", state.sectionsData, newData);
        state.sectionsData[existingDataIndex] = {
          id: newData?.section?.id,
          sequence: newData?.section?.sequence,
          name: newData?.section?.name,
          pages: {
            ...state.sectionsData[existingDataIndex].pages,
            ...newData.pages,
          },
        };
      } else {
        // If no data exists for this id, add it to the array
        state.sectionsData.push({
          id: newData?.section?.id,
          sequence: newData?.section?.sequence,
          name: newData?.section?.name,
          pages: newData?.pages,
        });
      }
      state.sectionsData.sort((a, b) => a.sequence - b.sequence);
    },
    setSectionData: (state, action) => {
      const newData = action.payload;

      state.sectionsData = [
        {
          id: newData?.section?.id,
          name: newData?.section?.name,
          pages: { ...newData.pages },
        },
      ];
    },
    setRespondantDetails(state, action) {
      state.respondentDetails = action.payload;
    },
    setActiveSurvey(state, action) {
      state.activeSurveys = action.payload;
    },
    setLoginTextCurrentView: (state, action) => {
      state.loginTextCurrentView = action.payload;
    },
    setUpdateStatuses: (state, action) => {
      state.updateStatuses = action.payload;
    },
    setDisableAdmin(state, action) {
      state.disableAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountryCodes.fulfilled, (state, action) => {
        state.countryCodes = action.payload;
      })
      .addCase(fetchQuestionsInPage.pending, (state) => {
        state.pageLoading = true;
      })
      .addCase(fetchQuestionsInPage.fulfilled, (state, action) => {
        state.pageLoading = false;
        state.questionsInPage = action.payload;
        state.pageError = null;
      })
      .addCase(fetchQuestionsInPage.rejected, (state, action) => {
        state.pageLoading = false;
        state.pageError = action.error.message;
      })
      .addCase(fetchNextPage.pending, (state) => {
        state.pageLoading = true;
      })
      .addCase(fetchNextPage.fulfilled, (state, action) => {
        state.pageLoading = false;
        state.nextPageDetails = action.payload;
        state.pageError = null;
      })
      .addCase(fetchNextPage.rejected, (state, action) => {
        state.pageLoading = false;
        state.pageError = action.error.message;
      })
      .addCase(fetchPreviousPage.pending, (state) => {
        state.pageLoading = true;
      })
      .addCase(fetchPreviousPage.fulfilled, (state, action) => {
        state.pageLoading = false;
        state.previousPageDetails = action.payload;
        state.pageError = null;
      })
      .addCase(fetchPreviousPage.rejected, (state, action) => {
        state.pageLoading = false;
        state.pageError = action.error.message;
      })
      .addCase(validateSurvey.pending, (state) => {
        state.errorPageLoading = true;
      })
      .addCase(validateSurvey.fulfilled, (state, action) => {
        state.errorPageLoading = false;
        state.errorPageDetails = action.payload;
      })
      .addCase(validateSurvey.rejected, (state, action) => {
        state.errorPageLoading = false;
        state.pageError = action.error.message;
      })
      .addCase(fetchSectionErrors.fulfilled, (state, action) => {
        state.errorPageDetails = {
          errors: action.payload,
          trigger: "errorChip",
        };
      })
      .addCase(fetchAllErrors.fulfilled, (state, action) => {
        state.errorPageDetails = {
          errors: action.payload,
          trigger: "errorLink",
        };
      })
      .addCase(getCorrectionQes.pending, (state) => {
        state.errorPageLoading = true;
      })
      .addCase(getCorrectionQes.fulfilled, (state, action) => {
        state.errorPageLoading = false;
        state.correctionPageDetails = action.payload;
      })
      .addCase(fetchRespondentDetailsByUsername.fulfilled, (state, action) => {
        state.respondentDetails = action.payload;
      })
      .addCase(getGlossaryData.fulfilled, (state, action) => {
        state.glossary = action.payload;
      })
      .addCase(fetchPrintSurveyMenu.fulfilled, (state, action) => {
        state.printSurveyMenu = action.payload;
      })
      .addCase(getLoginAuthenticated.fulfilled, (state, action) => {
        state.loggedInUserData = action.payload;
        // state.respondentDetails = action?.payload?.surveyRespondent;
      })
      .addCase(getBuilderUserData.fulfilled, (state, action) => {
        state.builderUserData = action.payload;
      })
      // .addCase(fetchActiveSurveysByRespondentId.fulfilled, (state, action) => {
      //   state.activeSurveys = action.payload;
      // })
      .addCase(fetchSurveyFiling.fulfilled, (state, action) => {
        state.surveyFiling = action.payload;
      })
      .addCase(getSurveyFiling.fulfilled, (state, action) => {
        state.surveyFilingDto = action.payload;
      })
      .addCase(fetchAllPagesBySurveyId.fulfilled, (state, action) => {
        state.allPages = action.payload;
      })
      .addCase(fetchSupPages.fulfilled, (state, action) => {
        state.supPages = action.payload;
      })
      .addCase(
        fetchSectionsBySurveyIdAndRespondenetId.fulfilled,
        (state, action) => {
          state.sectionFilings = action.payload;
        }
      )
      .addCase(fetchPrintSections.fulfilled, (state, action) => {
        state.printSecFilings = action.payload;
      })
      .addCase(fetchPageFilingsBySurveyFilingId.fulfilled, (state, action) => {
        state.pageFilings = action.payload;
      })
      .addCase(fetchSupPageFilings.fulfilled, (state, action) => {
        state.supPageFilings = action.payload;
      })
      .addCase(correctionQuesSave.pending, (state) => {
        state.errorPageLoading = true;
      })
      .addCase(correctionQuesSave.fulfilled, (state, action) => {
        state.correctionSaveResponse = action.payload;
        state.errorPageDetails = action.payload;
        state.errorPageLoading = false;
      })
      .addCase(correctionQuesSave.rejected, (state, action) => {
        state.errorPageLoading = false;
        state.pageError = action.error.message;
      })
      .addCase(fetchPageDataOfLastYear.fulfilled, (state, action) => {
        state.lastYearPageDataResponse = action.payload;
      })
      .addCase(fetchHistoricalFiling.fulfilled, (state, action) => {
        state.historicalFiling = action.payload;
      })
      .addCase(fetchSupplementalFiling.fulfilled, (state, action) => {
        state.supplementalFiling = action.payload;
      })
      .addCase(fetchSupSectionFiling.fulfilled, (state, action) => {
        state.supSectionFiling = action.payload;
      });
  },
});

export const {
  resetSectionsData,
  setQuestionsData,
  setDataIds,
  setPagePayload,
  setShowLanding,
  setShowErrorPage,
  setBuilderLogin,
  setShowFilingList,
  setTotalSectionData,
  setSectionData,
  setCombinedSectionFilings,
  setDisableSurvey,
  setRespondantDetails,
  setIsOpde,
  setBuilderOrgId,
  setActiveSurvey,
  setLoginTextCurrentView,
  setUpdateStatuses,
  setDisableAdmin,
} = surveySlice.actions;
export const surveyReducer = surveySlice.reducer;
