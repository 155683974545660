import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import {
  fetchPrintSections,
  fetchPrintSurveyMenu,
  fetchSectionData,
  resetSectionsData,
  setSectionData,
  setTotalSectionData,
} from "../Survey/Survey.slice";
import RenderRadioGrp from "../../components/QuestionTypes/RenderRadioGrp";
import RenderCheckboxGrp from "../../components/QuestionTypes/RenderCheckboxGrp";
import RenderCommonGrp from "../../components/QuestionTypes/RenderCommonGrp";
import RenderQuestion from "../../components/QuestionTypes/RenderQuestion";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../../components/Common/Popper";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import "../Survey/SurveyForm.scss";
import { getSurveyLookupFiling } from "../Survey/Survey.service";

const PrintSurvey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const sectionsData = useSelector((state) => state?.survey?.printSecFilings);
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );
  const surveyName = useSelector(
    (state) => state.survey.activeSurveys[0]?.name
  );
  const HospitalName = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.name
  );
  const ahaId = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.customId
  );
  const surveyMenu = useSelector((state) => state.survey.printSurveyMenu);
  const sectionsPageData = useSelector((state) => state.survey?.sectionsData);
  const [surveyData, setSurveyData] = useState([]);
  const [sectionsLoading, setSectionsLoading] = useState(false);
  const [sectionSelection, setSectionSelection] = useState(null);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [surveyId, setSurveyId] = useState(null);
  const [selectedSurFilId, setSelectedSurFilId] = useState(null);

  useEffect(() => {
    if (!surveyMenu.length) {
      dispatch(fetchPrintSurveyMenu({ surveyFilingId, surveyRespondentId }));
    }
  }, [dispatch]);

  useEffect(() => {
    let entireSurveyObj = {
      label: "Print Entire Survey",
      value: "totalSurvey",
    };
    let options = [
      entireSurveyObj,
      ...sectionsData.map((item) => ({
        label: "Print Section " + item?.section?.name,
        value: item?.section?.id,
      })),
    ];
    setSectionOptions(options);
  }, [sectionsData]);

  useEffect(() => {
    if (
      sectionsData?.length === sectionsPageData?.length &&
      sectionSelection === "totalSurvey"
    ) {
      setSurveyData(sectionsPageData);
      setSectionsLoading(false);
    } else {
      setSurveyData(sectionsPageData);
      setSectionsLoading(false);
    }
  }, [sectionsPageData]);

  const handleBack = () => {
    navigate(`/`, { state: { showFilingList: false } });
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const getPDFName = () => {
    let name = `${getTextFromHTML(
      surveyMenu?.surveys?.find((survey) => survey.id === surveyId)?.name
    )}-${HospitalName}`;
    return name.replace(/[.'"]/g, "");
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: getPDFName(),
    pageStyle: `
      @page {
        size: auto;
        margin: 11mm 17mm 17mm 17mm;

        @top-right {
            content: "Page " counter(page);
          }
      }
        .page-break {
          display: block;
          page-break-before: always;
        }
      }
    `,
    removeAfterPrint: true,
  });

  const handleSurveyToPrint = async (event) => {
    setSurveyId(event?.target?.value);
    if (event?.target?.value) {
      const response = await getSurveyLookupFiling({
        surveyId: event?.target?.value,
        surveyRespondentId,
      });

      setSelectedSurFilId(response?.data[0]?.id);

      const payload = {
        surveyFilingId: response?.data[0]?.id,
        surveyRespondentId: surveyRespondentId,
      };
      dispatch(fetchPrintSections(payload));
    }
    dispatch(resetSectionsData());
    setSectionSelection(null);
  };

  const handleSectionSelection = async (event) => {
    setSectionSelection(event?.target?.value);
    setSectionsLoading(true);
    if (event?.target?.value === "totalSurvey") {
      sectionsData.forEach(async (sec) => {
        let payload = {
          surveyId: surveyId,
          surveyFilingId: selectedSurFilId,
          sectionId: sec?.sectionId,
          surveyRespondentId: surveyRespondentId,
        };
        await dispatch(fetchSectionData(payload)).then((data) => {
          // Combine or merge the section data as needed
          // For simplicity, this example assumes you replace existing data

          dispatch(setTotalSectionData(data?.payload));
        });
      });
    } else if (event?.target?.value) {
      let payload = {
        surveyId: surveyId,
        surveyFilingId: selectedSurFilId,
        sectionId: event?.target?.value,
        surveyRespondentId: surveyRespondentId,
      };
      await dispatch(fetchSectionData(payload)).then((data) => {
        // Combine or merge the section data as needed
        // For simplicity, this example assumes you replace existing data

        dispatch(setSectionData(data?.payload));
      });
    }
  };

  return (
    <Grid
      sx={{
        "@media print": {
          marginLeft: "10px",
        },
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ fontSize: 25, fontWeight: "bold" }} color="primary">
          <b>{`Print Survey Menu`}</b>
        </Typography>
        <Typography color="primary">
          The
          <Typography display="inline" sx={{ fontWeight: "bold" }}>
            {" Survey Printing "}
          </Typography>
          option provides a hardcopy of your online AHA survey. You can printout
          the entire Survey, or select a section of the Survey.
        </Typography>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Typography color="primary">
          Each report is customized for your hospital. Depending upon the type
          of connection you have, it may take few moments to generate and
          download. We appreciate your patience.
        </Typography>
      </div>

      <div>
        <Typography sx={{ fontWeight: "bold" }} mb={1}>
          {"Select survey:"}
        </Typography>
        <FormControl size="small" sx={{ minWidth: "30%" }}>
          <Select
            id="SurveySelect"
            value={surveyId}
            placeholder="Please select survey"
            onChange={(event) => handleSurveyToPrint(event)}
          >
            {surveyMenu?.surveys?.map((opt) => (
              <MenuItem value={opt.id}>{getTextFromHTML(opt.name)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {surveyId && (
        <div my={2}>
          <Typography sx={{ fontWeight: "bold" }} mb={1} mt={3}>
            {"Select section:"}
          </Typography>
          <FormControl size="small" sx={{ minWidth: "30%" }}>
            <Select
              id="SurveySelect"
              value={sectionSelection}
              placeholder="Select section to print"
              onChange={(event) => handleSectionSelection(event)}
            >
              {sectionOptions.map((opt) => (
                <MenuItem value={opt.value}>
                  {getTextFromHTML(opt.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {sectionSelection && surveyData && (
        <div
          style={{
            width: "100%",
            textAlign: "start",
            paddingRight: "15px",
            paddingTop: "10px",
          }}
        >
          <div className="inner-box inner-box2">
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => handlePrint()}
            >
              Print
            </Button>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => handleBack()}
            >
              Back
            </Button>
          </div>
        </div>
      )}
      {sectionsLoading ? (
        <Box
          display="flex"
          sx={{ height: "100%", width: "100%" }}
          bgcolor="#d9e0ed"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <form ref={componentRef} ml={2}>
          {surveyData && sectionSelection && (
            <>
              <div style={{ marginBottom: "16px" }}>
                <Typography color="primary">
                  <b>{`${HospitalName} (${ahaId})`}</b>
                  <br />
                </Typography>
              </div>
              <div className="title">
                <Typography
                  sx={{ fontSize: 25, fontWeight: "bold" }}
                  color="primary"
                >
                  {getTextFromHTML(
                    surveyMenu?.surveys?.find(
                      (survey) => survey.id === surveyId
                    )?.name
                  )}
                </Typography>
              </div>
            </>
          )}
          {surveyData &&
            sectionSelection &&
            surveyData.map((section) => (
              <>
                <div className="title">
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      paddingBottom: "5px",
                    }}
                    color="primary"
                  >
                    {getTextFromHTML(section?.name)}
                  </Typography>
                </div>
                {Object.values(section?.pages).map((page) => (
                  <>
                    <div className="title">
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: "bold",
                          paddingBottom: "5px",
                        }}
                        color="primary"
                      >
                        {htmlParser(page?.page?.name)}
                      </Typography>
                    </div>
                    {page?.questionGroups.map((qes) => (
                      <>
                        {qes.groupType.name === "pdfpagebreakgrp" && (
                          <div className="page-break" />
                        )}
                        {qes.groupType.name === "radiogrp" && (
                          <RenderRadioGrp type="preview" questionsGrp={qes} />
                        )}
                        {qes.groupType.name === "checkboxgrp" && (
                          <RenderCheckboxGrp
                            type="preview"
                            questionsGrp={qes}
                          />
                        )}
                        {qes.groupType.name === "commongrp" && (
                          <RenderCommonGrp type="preview" questionsGrp={qes} />
                        )}
                        {qes.groupType.name !== "radiogrp" &&
                          qes.groupType.name !== "checkboxgrp" &&
                          qes.groupType.name !== "commongrp" &&
                          qes.questions &&
                          qes.questions.length > 0 &&
                          qes.questions.length === 1 &&
                          qes.questions.map((question) => {
                            return (
                              <RenderQuestion
                                type="preview"
                                question={question}
                                multiple={false}
                              />
                            );
                          })}
                        {qes.groupType.name !== "radiogrp" &&
                          qes.groupType.name !== "checkboxgrp" &&
                          qes.groupType.name !== "commongrp" &&
                          qes.questions?.length > 1 && (
                            <>
                              <StyledEngineProvider injectFirst>
                                <Card className="card">
                                  <CardContent className="card-header">
                                    <Grid container direction={"column"}>
                                      <Grid
                                        item
                                        alignSelf="start"
                                        className="question-container"
                                      >
                                        <Typography variant="question">
                                          {htmlParser(
                                            qes.descriptionPosition !== null ||
                                              qes.descriptionPosition !==
                                                "No Title"
                                              ? (qes.label || "") +
                                                  " " +
                                                  (qes.name || "")
                                              : (qes.label || "") +
                                                  " " +
                                                  (qes.description || "")
                                          )}
                                          {qes?.helpText && (
                                            <HelpTextPopper
                                              question={qes}
                                            ></HelpTextPopper>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <div>
                                      <Typography variant="h6">
                                        {htmlParser(
                                          qes.descriptionPosition !== null ||
                                            qes.descriptionPosition !==
                                              "No Title"
                                            ? qes.description || ""
                                            : ""
                                        )}
                                      </Typography>
                                    </div>
                                  </CardContent>
                                  <CardContent
                                    className="card-content"
                                    sx={{ backgroundColor: "#fff !important" }}
                                  >
                                    {qes.questions.map((question) => {
                                      return (
                                        <RenderQuestion
                                          type="preview"
                                          question={question}
                                          multiple={true}
                                        />
                                      );
                                    })}
                                  </CardContent>
                                </Card>
                              </StyledEngineProvider>
                            </>
                          )}
                      </>
                    ))}
                  </>
                ))}
                <p style={{ pageBreakAfter: "always" }}></p>
              </>
            ))}

          <div
            class="hideBtn"
            style={{
              width: "100%",
              textAlign: "start",
              paddingRight: "15px",
              paddingTop: "10px",
            }}
          >
            <div className="inner-box inner-box2">
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                color={"primary"}
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </div>
          </div>
        </form>
      )}
    </Grid>
  );
};

export default PrintSurvey;
